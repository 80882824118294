<template>
  <div>
    <div style="margin: 10px 0">
      <el-select v-model="status" placeholder="请选择状态" >
        <el-option
            v-for="item in [
                {
              name:'漂流中',
              value:0
            },
            {
              name:'申请中',
              value:1
            },
            // {
            //   name:'正在阅读',
            //   value:2
            // },
                {
              name:'借阅完成',
              value:2
            }]"
            :key="item.value"
            :label="item.name"
            :value="item.value">
        </el-option>
      </el-select>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>
    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"
              @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="recordId" width="100" label="ID" sortable></el-table-column>
      <el-table-column prop="book.user.nickname" label="所属用户" width="120" sortable></el-table-column>
      <el-table-column prop="book.author" label="作者" width="300" sortable></el-table-column>
      <el-table-column prop="book.publishedYear" label="出版年份" width="120" sortable></el-table-column>
      <el-table-column prop="book.publisher" label="出版社" width="200" sortable></el-table-column>
      <el-table-column prop="book.user.nickname" label="申请人" width="120" sortable></el-table-column>
      <el-table-column prop="phone" label="联系方式" width="120" sortable></el-table-column>
      <el-table-column prop="address" label="领取地址" width="120" sortable></el-table-column>
      <el-table-column label="状态" width="120" sortable>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status==='0'" type="info">漂流中</el-tag>
          <el-tag v-if="scope.row.status==='1'" type="warning">申请中</el-tag>
<!--          <el-tag v-if="scope.row.status==='2'" >正在阅读</el-tag>-->
          <el-tag v-if="scope.row.status==='2'" type="success">借阅完成</el-tag>
        </template>
      </el-table-column>

<!--      <el-table-column label="操作" align="center">-->
<!--        <template slot-scope="scope">-->
<!--&lt;!&ndash;          <el-popconfirm&ndash;&gt;-->
<!--&lt;!&ndash;              v-if="scope.row.status==='2'"&ndash;&gt;-->
<!--&lt;!&ndash;              class="ml-5"&ndash;&gt;-->
<!--&lt;!&ndash;              confirm-button-text='确定'&ndash;&gt;-->
<!--&lt;!&ndash;              cancel-button-text='我再想想'&ndash;&gt;-->
<!--&lt;!&ndash;              icon="el-icon-info"&ndash;&gt;-->
<!--&lt;!&ndash;              icon-color="green"&ndash;&gt;-->
<!--&lt;!&ndash;              title="您确定完成吗？"&ndash;&gt;-->
<!--&lt;!&ndash;              @confirm="update(scope.row,3)"&ndash;&gt;-->
<!--&lt;!&ndash;          >&ndash;&gt;-->
<!--&lt;!&ndash;            <el-button type="success" slot="reference">借阅完成 <i class="el-icon-notebook-1"></i></el-button>&ndash;&gt;-->
<!--&lt;!&ndash;          </el-popconfirm>&ndash;&gt;-->
<!--&lt;!&ndash;          <el-button @click="reminderReading(scope.row)" v-if="scope.row.status==='2'" slot="reference">提醒阅读 <i class="el-icon-notebook-1"></i></el-button>&ndash;&gt;-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Drifrecord",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      status: "",
      form: {},
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.request.get("/driftRecord/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          status: this.status
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    save() {
      this.request.post("/driftRecord", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    update(record,status) {
      record.status = status;
      this.request.post("/driftRecord", record).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    reminderReading(record) {
      let data ={
        "readMark":0,
        "userId":record.userId,
        "messageType":2,
        "content":"请尽快阅读"+record.book.name+"书籍",
      };
      this.request.post("/msg", data).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
      this.$nextTick(() => {
        if (this.$refs.img) {
          this.$refs.img.clearFiles();
        }
        if (this.$refs.file) {
          this.$refs.file.clearFiles();
        }
      })
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
      this.$nextTick(() => {
        if (this.$refs.img) {
          this.$refs.img.clearFiles();
        }
        if (this.$refs.file) {
          this.$refs.file.clearFiles();
        }
      })
    },
    del(id) {
      this.request.delete("/driftRecord/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      if (!this.multipleSelection.length) {
        this.$message.error("请选择需要删除的数据")
        return
      }
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/driftRecord/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.status = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    },
    exp() {
      window.open("http://localhost:9090/drifrecord/export")
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee !important;
}
</style>
